import { useNavigate, useLocation } from "react-router-dom";
import WorkerCard from "../components/WorkerCard";
import styles from "../styles/WorkerPage.module.css";
import NavBar from "../components/NavBar";
import { sendAnalytics } from "../functions/api/sendAnalytics";
import { AnalyticsData } from "../classes/AnalyticsData";
import { textPay } from "../consts/ConstSevicePage";

const WorkerPage = ({}) => {
  const languageCode = window?.Telegram?.WebApp?.initDataUnsafe?.user?.language_code === "ru" ? "ru" : "en";
  const navigate = useNavigate();
  const { state } = useLocation();
  const { workerData, serviceName, prevState } = state;

  let priceFiat =
    workerData.service.find((price) => price.currency === "RUB")
      ?.amountPerHour ?? "wrong";
  let priceCrypt =
    workerData.service.find((price) => price.currency === "USDT")
      ?.amountPerHour ?? "wrong";

  function getSubmitHandler(price, currency) {
    const hoursAmount = 1;
    return async function () {
      let result = await sendAnalytics(
        new AnalyticsData({
          type: "PopupExpertsAreBusy",
          hoursAmount: hoursAmount,
          price: price,
          currency: currency,
          service: serviceName,
          id: workerData._id,
        })
      );
      if (result) {
        // window.Telegram.WebApp.openTelegramLink("https://t.me/easehow");
        // window.Telegram.WebApp.close();
      }
    };
  }

  return (
    <div className="container">
      <WorkerCard name={workerData.nickname} priceList={workerData.service} desrciptionData={workerData.description}/>

      {/* <button onClick={getSubmitHandler(priceFiat, "RUB")}>Оплатить RUB</button> */}
      <button onClick={getSubmitHandler(priceCrypt, "USDT")}>
        {textPay}
      </button>
        <div className={styles.descriptionText}>
      <p>
          {workerData?.description?.descriptionText[languageCode]
            .split("\n")
            .map(function (line, i) {
              return (
                <span key={i}>
                  {line}
                  <br />
                </span>
              );
            })}
      </p>
        </div>
      {/* <button>поделиться</button> */}

      <nav>
        <NavBar
          currentPagePath={"/worker"}
          currentPathState={state}
          prevPagePath={"/list"}
          prevPageState={prevState}
        />
      </nav>
    </div>
  );
};

export default WorkerPage;
